<!--
 * @Description: 确认入库弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-02 20:33:56
 * @LastEditTime: 2023-02-21 18:31:53
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    title="确认入库"
    :visible.sync="confirmVisible"
    :close-on-click-modal="false"
    width="950px"
    v-loading="editDialogLoading"
    :element-loading-background="loadingBackground"
    custom-class="custom-dialog"
    :before-close="closeDialog"
    append-to-body
  >
    <el-form
      label-width="120px"
      ref="addForm"
      class="detailForm"
      :inline="true"
    >
      <el-form-item label="所属主体：">
        {{ clickRow.inCompanyName }}
      </el-form-item>
      <el-form-item label="调入仓库：">
        {{ clickRow.inWarehouseName }}
      </el-form-item>
    </el-form>
    <el-table
      ref="detailTable"
      :data="tableData"
      highlight-current-row
      @select="onTableSelect"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="50"></el-table-column>
      <el-table-column
        show-overflow-tooltip
        v-for="(column, index) in columnData"
        :key="index"
        :prop="column.fieldName"
        :label="column.fieldLabel"
        :min-width="column.width"
        :fixed="column.fixed"
        :align="column.align"
        header-align="center"
      >
      </el-table-column>
    </el-table>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { getInWarehouseApplyDetail, batchInWarehouseConfirm } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "confirmationWarehouse",
  components: {},
  data() {
    return {
      editDialogLoading: false,
      tableData: [],
      applyIdList: [],
      columnData: [
        {
          fieldName: "applyDetailId",
          display: true,
          fieldLabel: "id",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialName",
          display: true,
          fieldLabel: "物料名称",
          width: 110,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialType",
          display: true,
          fieldLabel: "类型",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialNo",
          display: true,
          fieldLabel: "物品编码",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "amount",
          display: true,
          fieldLabel: "数量",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipNo",
          display: true,
          fieldLabel: "物料详情",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "fixedAssetsTypeName",
          display: true,
          fieldLabel: "固资/存货",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      detailTableData: []
    };
  },
  props: {
    confirmVisible: {
      type: Boolean,
      default: false
    },
    clickRow: {
      type: Object,
      default: () => {
        return {
          inCompanyName: ""
        };
      }
    }
  },
  mixins: [base],
  computed: {},
  created() {},
  watch: {
    confirmVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.initData();
        }
      }
    }
  },
  methods: {
    async initData() {
      try {
        this.editDialogLoading = true;
        let promiseList = [
          getInWarehouseApplyDetail({
            applyIdList: this.clickRow.inWarehouseApplyId
          })
        ];
        let [detailRes] = await Promise.all(promiseList);
        let tableData = detailRes.data.details;
        this.applyIdList = detailRes.data.applyIdList;
        tableData.map(item => {
          item.inAmount = item.amount;
        });
        this.tableData = tableData;
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    onTableSelect(rows, row) {
      this.$nextTick(() => {
        this.$refs.detailTable.toggleRowSelection(row);
        let selected = rows.length && rows.indexOf(row) !== -1;
        this.tableData.forEach(item => {
          if (item.applyId == row.applyId) {
            this.$refs.detailTable.toggleRowSelection(item, selected);
          }
        });
      });
    },
    //勾选数据
    handleSelectionChange(columns) {
      this.detailTableData = columns;
    },
    //点击确定添加
    handleSubmit() {
      this.inWarehouseConfirmReq();
    },
    async inWarehouseConfirmReq() {
      try {
        this.editDialogLoading = true;
        let applyIdList = this.applyIdList.filter(item => {
          return this.detailTableData.find(each => each.applyId == item.id);
        });
        let res = await batchInWarehouseConfirm({
          applyIdList,
          warehouseNo: this.clickRow.inWarehouseNo,
          warehouseName: this.clickRow.inWarehouseName,
          warehouseOrgNo: this.clickRow.inCompanyNo,
          warehouseOrgName: this.clickRow.inCompanyName,
          details: this.detailTableData
        });
        if (res.code == "OK") {
          this.$message.success("入库成功");
          this.$emit("refreshData");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //关闭弹框
    closeDialog() {
      this.$emit("update:confirmVisible", false);
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    .el-input {
      width: 220px;
    }
  }
}
</style>
